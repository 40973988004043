<template>
    <div class="aplayz_mo_space_modal music_info" :class="isactive">
        <div v-if="isactive === 'active'" class="background_dim"></div>
        <div class="mo_comment_report_header" v-if="musicinfo">
            <img class="mo_comment_report_img" :src="musicinfo.albumImg" alt="" width="42" height="42" />
            <div class="mo_music_info">
                <span class="mo_music_info_music_title">{{ musicinfo.musicNm }}</span>
                <span class="mo_music_info_music_artist">{{ musicinfo.artist }}</span>
            </div>
            <img class="mo_close" src="/media/images/icon_close_24.svg" @click="$emit('close-musicinfo')" />
        </div>
        <ul class="mo_comment_report_body">
            <li class="mo_comment_report_item cursor" @click="$emit('open-comment')">
                <img class="mo_comment_report_icon" src="/media/img/icon_pc_comment_30.svg" alt="한줄평" />
                <span class="mo_comment_report_icon_nm">한줄평</span>
            </li>
            <li class="mo_comment_report_item cursor" @click="$emit('click-dislike')">
                <img class="mo_comment_report_icon" :src="isUnLike ? '/media/img/hate_icon_active.svg' : '/media/img/hate_icon_off.svg'" alt="싫어요" />
                <span class="mo_comment_report_icon_nm">싫어요</span>
            </li>
            <li class="mo_comment_report_item cursor" @click="$emit('open-report')">
                <img class="mo_comment_report_icon" src="/media/img/icon_pc_report_30.svg" alt="신고하기" />
                <span class="mo_comment_report_icon_nm">신고하기</span>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
  name: 'MusicInfoMobile',
  emits: ['close-musicinfo', 'open-comment', 'click-dislike', 'open-report'],
  props: {
    isactive: {
      type: String,
      requried: false,
      default: ''
    },
    musicinfo: {
      type: Object,
      requried: false,
      default: () => {
        return {
          musicNm: '',
          artist: '',
          musicId: '',
          albumImg: ''
        };
      }
    }
  },
  data () {
    return {};
  },

  mounted () {},

  methods: {},
  computed: {
    isUnLike () {
      const { unlike } = this.$store.getters.getLikeInfo;
      if (unlike === 'Y') {
        return true;
      }
      return false;
    }
  }
};
</script>
<style scoped src="@/assets/css/player/mobiletemplate.css"></style>
